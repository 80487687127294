import { Divider } from 'antd'
import React, { CSSProperties } from 'react'

interface BreakProps {
    text?: React.ReactNode;
    children?: React.ReactNode;
    style?: CSSProperties;
    type?: 'horizontal' | 'vertical';
    n?: string | number;
    e?: string | number;
    s?: string | number;
    w?: string | number;
    v?: string | number;
    h?: string | number;
    m?: string | number;
    dashed?: boolean;
    orientation?: 'center' | 'left' | 'right'
    air?: boolean
    c?: boolean
    o?: boolean
}

export default function Break(props: BreakProps) {
    const hor = props.type != 'vertical'
    const {
        n = hor ? 5 : 0,
        e = hor ? 0 : 5,
        s = hor && (!props.air && !props.s) ? 5 : 0,
        w = hor ? 0 : 5,
        h,
        v,
        m = 0
    } = props
    const up = (props.o) ? `${n || v || m}` : `${v || n || m}`;
    const down = (props.o) ? `${s || v || m}` : `${v || s || m}`;
    const left = (props.o) ? `${w || h || m}` : `${h || w || m}`;
    const right = (props.o) ? `${e || h || m}` : `${h || e || m}`;
    const style = { margin: props.c ? '0px 0px 0px 0px' : `${up}px ${right}px ${down}px ${left}px`, ...props.style }
    if (props.air) return <div style={style} />
    return (<Divider style={style} type={props.type} dashed={props.dashed} orientation={props.orientation}>{props.text || props.children}</Divider>)
}