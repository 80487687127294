import React, { CSSProperties } from 'react';

import GPop, { GlobalPopProps } from './global/GPop';
import GTooltip from './global/GTooltip';

type HoverQuestionProps = {
    title?: React.ReactNode
    style?: CSSProperties
    dangerous?: boolean
    popover?: boolean
    popoverProps?: GlobalPopProps
}

export default function HoverQuestion({ title, style, dangerous, ...props }: HoverQuestionProps) {

    if (!title) return (<i className="fa-regular fa-circle-question" style={{ ...style }}></i>)

    if (props.popover) return (<>
        <GPop content={title} {...props.popoverProps}>
            <div>
                <i className="fa-regular fa-circle-question" style={{ ...style }}></i>
            </div>
        </GPop>
    </>)

    return (<>
        <GTooltip tooltip={title} dangerous={dangerous}>
            <i className="fa-regular fa-circle-question" style={{ ...style }}></i>
        </GTooltip>
    </>)
}
