import React, { CSSProperties, ReducerAction } from 'react';
import Highlighter from 'react-highlight-words';

import { colors, colorUtils, utils } from '../helpers';
import FA from './global/FA';
import GTooltip from './global/GTooltip';

export interface HighlightTextProps {
	text?: string; // input string
	search?: string | (string | RegExp)[]; // string to highlight
	minChar?: number; // minium chars it will search for
	maxChar?: number; // minium chars it will search for
	props?: any; // props of text default
	highlight?: string; // color of highlight
	style?: CSSProperties;
	textStyle?: CSSProperties;
	wrapperStyle?: CSSProperties;
	copyStyle?: CSSProperties;
	textProps?: any;
	tooltip?: boolean | string;
	hideTooltip?: boolean;
	linked?: boolean;
	onClick?: () => void;

	linkTo?: string;
	blank?: boolean;

	copyable?: boolean | string;
}

export default function HighlightText({ text, search, ...props }: HighlightTextProps) {
	const [copied, setCopied] = React.useState(false);

	let displayText = text || '',
		shortened = false;

	if (props.maxChar && displayText.length > props.maxChar) {
		displayText = `${(text || '').substring(0, props.maxChar)}...`;
		if (search === text) search = `${(search || '').substring(0, props.maxChar)}`;
		shortened = true;
	}

	const searchWords = Array.isArray(search) ? search : [search || ''];

	let tooltip = props.tooltip || (shortened ? text : '');
	if (props.tooltip === true) {
		tooltip = shortened ? text : '';
		props.tooltip = false;
	}
	if (props.hideTooltip || !tooltip) tooltip = '';

	// const searchWords = (Array.isArray(search) ? search : [search]) as string[]

	// return <GTooltip tooltip={props.hideTooltip ? '' : (typeof props.tooltip === 'string' ? props.tooltip : shortened || props.tooltip ? str : '')}>
	//     <Highlighter
	//         autoEscape={true}
	//         searchWords={searchWords}
	//         textToHighlight={displayName}
	//         highlightStyle={{ ...props?.style, background: props.highlight || 'rgba(44, 221, 155, 0.8)', borderRadius: 3 }}
	//         className='highlight-wrapper'
	//         style={{ ...props?.style, ...props.textStyle }}
	//     />
	// </GTooltip>

	const LinkWrapped = ({ children }: { children: React.ReactNode }) => {
		if (!props.linkTo) return <>{children}</>;
		return (
			<a
				href={props.linkTo}
				target={props.blank ? '_blank' : undefined}
				style={{ textDecoration: 'none', color: 'inherit' }}
			>
				{children}
			</a>
		);
	};
	return (
		<span
			style={{
				...props.wrapperStyle,
				...(!!props.copyable && { cursor: 'pointer' }),
			}}
			onClick={() => {
				if (props.onClick) props.onClick();
				else if (!!props.copyable) {
					const copyText = typeof props.copyable === 'string' ? props.copyable : text;
					navigator.clipboard.writeText(copyText || '');
					setCopied(true);
					setTimeout(() => setCopied(false), 2000);
					utils.showSuccess('Copied to clipboard');
				}
			}}
		>
			<LinkWrapped>
				<GTooltip tooltip={tooltip}>
					<Highlighter
						autoEscape={true}
						searchWords={searchWords}
						textToHighlight={displayText}
						className={`highlight-wrapper ${props.linked ? 'linked' : ''}`}
						style={{
							...props?.style,
							...props.textStyle,
						}}
						highlightStyle={{
							...props?.style,
							background: props.highlight || colorUtils.setHexAlpha(colors.primary.primary, 0.5),
							borderRadius: 6,
						}}
					/>
				</GTooltip>
			</LinkWrapped>
			{!!props.copyable && (
				<FA
					name={copied ? 'check' : 'copy'}
					style={{
						cursor: 'pointer',
						fontSize: '.75rem',
						marginLeft: 6,
						color: copied ? '#2CDD9B' : colors.primary.blue || 'inherit',
						...props.copyStyle,
					}}
				/>
			)}
		</span>
	);
}

export function Highlight({ text, search, ...props }: { text: string; search: string; highlight?: string }) {
	const searchWords = Array.isArray(search) ? search : [search || ''];
	return (
		<Highlighter
			text={text}
			autoEscape={true}
			searchWords={searchWords}
			textToHighlight={text}
			highlightStyle={{
				background: props.highlight || colorUtils.setHexAlpha(colors.primary.primary, 0.5),
				borderRadius: 6,
			}}
		/>
	);
}
